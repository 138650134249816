main {
  margin: auto;
  max-width: 960px;
}

.table-container {
  margin: 10px;
  padding: 0;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

tr.expandable > td {
  padding: 0;
}

tr.expandable > td > .inner {
  margin: 15px;
  overflow: hidden;
}
