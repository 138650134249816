.header {
  background-color: $primary;
  height: 70px;
  width: 100%;
  display: flex;
}

.header-logo {
  margin: 1rem auto;
}
